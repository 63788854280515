<template>
  <div class="tab-pane active">
    <div class="responsive-table">
      <div class="loading min-h-300" v-if="loading">
        <LoadingAnim />
      </div>
      <table v-else-if="files.length > 0" class="table">
        <thead class="">
          <tr>
            <th scope="col">Formato</th>
            <!-- Data -->
            <th scope="col">{{ $t('app.date') }}</th>
            <!-- Remetente -->
            <th scope="col">Status</th>
            <!-- Destinatario -->
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.id">
            <td data-label="Data">
              <div>
                {{ file.format }}
              </div>
            </td>
            <td data-label="Tipo">
              {{ file.created_at | formatDate24 }}
            </td>
            <td data-label="Status">
              <span
                v-if="file.status == 'QUEUED'"
                class="badge badge-pill badge-light"
                >{{ $t('generic-str.status.lbl-queue') }}</span
              >
              <span
                v-if="file.status == 'IN_PROGRESS'"
                class="badge badge-pill badge-warning"
                >Em Processo</span
              >
              <span
                v-if="file.status == 'FAILED'"
                class="badge badge-pill badge-danger"
                >{{ $t('generic-str.status.lbl-failure') }}</span
              >
              <span
                v-else-if="file.status == 'EXPORTED'"
                class="badge badge-pill badge-success"
                >{{ $t('generic-str.status.lbl-exported') }}</span
              >
            </td>
            <td data-label="Destinatário">
              <custom-button
                v-if="file.status == 'EXPORTED'"
                class="btn btn-success btn-sm"
                :loading="file.downloading"
                @click="goTo(file.url)"
              >
                <span class="material-symbols-outlined down-icon">download</span>
              </custom-button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <pagination :lastPage="pages" @change="fetch" />
      </div>
      <div class="no-item-list" v-if="files.length == 0 && !loading">
        <span class="material-symbols-outlined">cloud_download </span>
        <span>Nenhum arquivo</span>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SmsService from '@/services/sms.service';
import Pagination from '@/components/Pagination.vue';
import CustomButton from '@/components/CustomButton.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'SmsHistoryTab',
  components: {
    // PageHeader,
    Pagination,
    LoadingAnim,
    CustomButton,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: true,
      files: [],
      form: {
        id: '',
        status: '',
        from: '',
        to: '',
        created_at: {
          gte: '',
          lte: '',
        },
        order_by: ['scheduled_at', 'desc'],
        page: 1,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.files = [];
      this.loading = true;

      webApi.post('/whatsapp/new/report/all', {
        from: this.filters.gte,
        to: this.filters.lte,
        page,
        size: 10,
      }).then((response) => {
        console.log('Export');
        console.log(response);
        this.loading = false;
        this.files = response.data.content;
        this.pages = response.data.totalPages;
      }, (error) => {
        this.loading = false;
        this.content = error;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
    exportData(format) {
      this.$store.dispatch('exportSmsMessages', {
        filter: this.filters,
        export: format,
      });
    },
    exportMessages() {
      this.exporting = true;
      SmsService.exportSms({
        filter: {
          id: this.form.id,
          from: this.form.from,
          to: this.form.to,
          status: this.form.status,
          sent_at: {
            from: this.beginDate,
            to: this.endDate,
          },
        },
      })
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.toast.report'),
              type: 'success',
            });
            this.$router.push('/sms/exports');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.exporting = false;
        });
    },
    exportFileSMS(file) {
      file.downloading = true;
      this.$store
        .dispatch('downloadSmsReport', file.id)
        .then(
          (response) => {
            this.fileExport(response, file.name);
          },
          (error) => {
            this.content = error;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          file.downloading = false;
        });
    },
    goTo(urlParam) {
      window.location.href = urlParam;
    },
    fileExport(response, name) {
      if (response.headers['content-type'] === 'application/zip') {
        name = `${name}.zip`;
      } else {
        name = `${name}.csv`;
      }
      const data = response.data;
      const blob = new Blob([data], { type: response.headers['content-type'] });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, name);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', name);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}

/* Rebranding */
.rebranding .card.borderless {
  box-shadow: unset !important;
}

.form-group {
  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-success {
  color: #6eda2c;
}
.icon-failed {
  color: #ff0000;
}
table {
  border-collapse: separate !important;
  border-spacing: 0 10px !important;
  &.busy {
    opacity: 0.55;
  }
}
.table th,
.table thead th {
  border: none;
}

td,
th {
  vertical-align: middle;
}
tr td {
  padding: 18px;
}
td {
  border: 1px solid #e5e5e5;
  border-style: solid none;
}

td:first-child {
  border-left-style: solid;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

td:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}
.text-primary {
  color: var(--clr-yup-purple) !important;
}
.link-out-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

}
.link-message {
  margin-left: 3px;
  font-size: 18px;
}
.no-item-list {
  padding-top: 60px;
  width: 30%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #c7c7c7;
}
.no-item-list .material-symbols-outlined {
  font-size: 90px;
  margin-bottom: 10px;
}
.down-icon {
  font-size: 18px !important;
  color: #fff !important;
}
</style>
